<template>
<div id="knowhow-item-container">
  <div class="knowhow-contents">
    <div class="knowhow-title">
      <p class="black-light">서베이지의 EASY한 서베이 꿀팁 #5</p>
      <p class="green">설문조사 Do & Don’t</p>
    </div>
    <div class="knowhow-content">
      <p><img class="img1" src="@/assets/knowhow/one/surveytips5thumbnail.png" width="300"></p>
      <p class="black-bold-">가장 쉽게 진행할 수 있는 리서치 수단인 만큼 흔히 발생하는 실수들이 정해져 있는 설문조사!<br>이번 아티클을 통해 가장 쉽게 범할 수 있는 실수와 개선 방법을 알아봅시다.</p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">적당한 양의 선택지 개수</p>
      <p class="black-light">질문에서 선택지를 너무 많이 제시하면 응답자가 혼란스럽거나 지칠 수 있습니다. 적당한 양의 선택지를 제시하고 주관식 ‘기타’ 옵션을 추가해 주세요.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips5image(1).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">객관적인 질문 설계</p>
      <p class="black-light">질문자의 의도와 의견이 나타나는 유도형 질문은 왜곡된 응답을 불러올 수 있습니다. 최대한 솔직한 응답을 수집하기 위해서는 객관적인 질문으로 설계해야 합니다. 예를 들어, ‘저희 서비스에서 어떤 점이 좋으신가요?’라고 묻는 질문은 응답자가 해당 서비스를 좋아한다는 것을 유도하고 있습니다. ‘저희 서비스에 대해 얼마나 만족하시나요?’라고 묻는 질문이 더 객관적인 응답을 가져올 수 있습니다.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips5image(2).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">문항 당 1가지만 질문</p>
      <p class="black-light">하나의 문항에서 2가지 이상에 대한 의견을 물어보는 질문을 해서는 안 돼요. 예를 들어, ‘음주와 흡연이 몸에 나쁘다고 생각하시나요?’라는 질문은 음주와 흡연에 대한 의견을 동시에 물어보는 오류를 범합니다. 만약 음주는 괜찮고, 흡연은 몸에 나쁘다고 생각하는 응답자라면 답변하기 어렵겠죠? 하나의 문항에서 1가지만 물어볼 수 있도록 질문을 구성해야 합니다.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips5image(3).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">설문 대상 명확히 하여 배포</p>
      <p class="black-light">설문조사 응답 수만큼이나 중요한 것은, 응답의 퀄리티입니다. 응답의 양을 늘리기 위해 불특정 다수에게 설문을 배포해서는 안 됩니다. 유의미한 데이터 수집을 위해 리서치에서 타깃으로 하는 대상에게만 설문을 배포해야 합니다.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips5image(4).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">설문 배포 전 확인</p>
      <p class="black-light">설문을 다 완성한 후에, 마지막으로 검토하는 습관이 필요합니다. 문항 구성이나 순서가 올바르게 되어있는지, 선택지 구성에 오류는 없는지, 자잘한 오타가 있는지 등을 체크한 후 배포해야 합니다. 마지막으로, 배포 링크가 PC와 모바일에서 모두 제대로 작동하는지 확인하고 배포합시다!</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips5image(5).png" width="400"></p>
      <p><img src="@/assets/knowhow/08.png" class="img2" width="30"></p>
        <p class="black-bold-">설문조사 응답의 질을 높이는 소소한 습관을 실천하며<br>
        유의미한 자료를 수집할 수 있으면 좋겠습니다 :)</p>
    </div>
  </div>
</div>

</template>
 
<script>
export default {
  methods: {
   
  }
}
</script>

<style>
#knowhow-item-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Noto Sans KR', sans-serif;
    margin-top: 60px;
    text-align: center;
}

#knowhow-item-container .knowhow-title p {
    font-size: 1.2rem;
    font-weight: 500;
    color: #0AAB00;
    margin-top: 6px;
}

#knowhow-item-container .knowhow-title .black-light {
    font-size: 1rem;
    font-weight: 300;
    color: black;
    padding-left: 10%;
    text-align: left;
    margin-bottom: 0;
    line-height: 30px;
}

#knowhow-item-container .img1 {
    margin-top: 50px;
}

#knowhow-item-container .img {
    margin-top: 30px;
    padding-left: 0 !important;
}

.knowhow-content {
    margin-bottom: 120px;
    padding-right: 10%;
    padding-left: 10%;
}

.knowhow-content .black-light {
    font-weight: 300;
    text-align: left;
    font-size: 1rem;
    line-height: 30px;

}

.knowhow-content .black-bold {
    font-weight: 700;
    font-size: 1.1rem;
    text-align: left;
}

.knowhow-content .img2 {
    margin-top: 100px;
    padding-left: 0 !important;
    margin-bottom: 20px;
}

.knowhow-content .black-bold- {
    font-size: 0.9rem;
    color: gray;
    font-weight: 400;
    line-height: 30px;

}

.green {
    padding-left: 10%;
    text-align: left;
}</style>