<template>
  <div class="service-detail-container">
    <div class="full-detail-container">
      <img src="@/assets/service/service_detail.png" alt="detail">
    </div>
    
  </div>
</template>

<script>
export default {
  
}
</script>

<style>
.service-detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: space-around;
  margin-top: 5px;
  
}
.full-detail-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: space-around;
}
.home-title{
  margin: 0;
}
</style>