<template>

<div id="knowhow-item-container">
  <div class="knowhow-contents">
    <div class="knowhow-title">
      <p class="black-light">서베이지의 EASY한 서베이 꿀팁 #2</p>
      <p class="green">설문조사를 진행할 만한 주제는 어떤 것일까?</p>
    </div>
    <div class="knowhow-content">
      <p><img class="img1" src="@/assets/knowhow/one/surveytips2thumbnail.png" width="300"></p>
      <p class="black-bold-">서베이지의 EASY한 꿀팁 두 번째에서는 <br>설문조사를 진행할 만한 주제를 판단하는 법을 알려드립니다.</p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">설문조사의 목적과 특징</p>
      <p class="black-light">설문조사 주제가 타당한지 판단하기 위해서는 먼저 설문조사를 하는 이유와 특징을 파악하는 것이 중요합니다. 설문조사는 우리가 어떤 집단으로부터 특정한 정보를 단기간에 얻고자 할 때 흔하게 쓰이는 수단 중 하나입니다. 설문조사 특성상, 다양한 객관식 질문과 짧은 주관식 문항 위주로 이루어져 있어 통계적인 데이터를 빠르게 얻는 데에 유용합니다.</p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">그럼 어떤 주제로 설문을 진행할까</p>
      <p class="black-light">이러한 설문조사의 특징을 고려했을 때, 설문조사를 진행하기 좋은 카테고리로는 ‘인식 조사’, ‘만족도 조사’ 등이 있습니다. 또한, 설문조사는 다수를 대상으로 특정한 주제나 사회 문제에 대해 다양한 의견을 수렴하기 좋습니다.</p>
        <p><img class="img" src="@/assets/knowhow/one/surveytips2image(1).jpeg" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">이런 식의 주제는 피하자</p>
      <p class="black-light">다만, 수치로 표현하기 어려운 주관적인 내용이나 깊은 인사이트를 얻기 위해서는 설문조사보다 인터뷰 등의 리서치 방식이 더 효과적이라고 할 수 있습니다.</p>
        <p><img class="img" src="@/assets/knowhow/one/surveytips2image(2).jpeg" width="500"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">주제를 정한 후,유의해야 할 점</p>
      <p class="black-light">설문조사는 적당한 길이를 유지해야 하기 때문에, 효율적으로 질문을 하는 것이 중요합니다. 아래와 같은 사항들을 고려하여 버려지는 문항이 없게끔 유의할 필요가 있습니다.</p>
        <p><img class="img" src="@/assets/knowhow/one/surveytips2image(3).png" width="500"></p>
        <p><img src="@/assets/knowhow/08.png" class="img2" width="30"></p>
        <p class="black-bold-">설문을 제작하기 전에 이번 아티클을 읽으며 <br>
        ”이 주제로 설문조사를 진행해도 될까?”라는 질문을 스스로에게 던져보시길 바랍니다. <br>
        유의미한 리서치를 하기 위해서는 설문조사 주제를 정하는 단계에서부터 신중해야 합니다.</p>
        
    </div>
  </div>
</div>

</template>
 
<script>
export default {
  methods: {
   
  }
}
</script>

<style>
#knowhow-item-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Noto Sans KR', sans-serif;
    margin-top: 60px;
    text-align: center;
}

#knowhow-item-container .knowhow-title p {
    font-size: 1.2rem;
    font-weight: 500;
    color: #0AAB00;
    margin-top: 6px;
}

#knowhow-item-container .knowhow-title .black-light {
    font-size: 1rem;
    font-weight: 300;
    color: black;
    padding-left: 10%;
    text-align: left;
    margin-bottom: 0;
    line-height: 30px;
}

#knowhow-item-container .img1 {
    margin-top: 50px;
}

#knowhow-item-container .img {
    margin-top: 30px;
    padding-left: 0 !important;
}

.knowhow-content {
    margin-bottom: 120px;
    padding-right: 10%;
    padding-left: 10%;
}

.knowhow-content .black-light {
    font-weight: 300;
    text-align: left;
    font-size: 1rem;
    line-height: 30px;

}

.knowhow-content .black-bold {
    font-weight: 700;
    font-size: 1.1rem;
    text-align: left;
}

.knowhow-content .img2 {
    margin-top: 100px;
    padding-left: 0 !important;
    margin-bottom: 20px;
}

.black-bold- {
    font-size: 1rem;
    color: gray;
    font-weight: 400;
    line-height: 30px;

}

.green {
    padding-left: 10%;
    text-align: left;
}</style>