<template>
<div id="app">
  <div class="main-container">
    <HeaderVue />
    <router-view/>
    <FooterVue class="footer" />
  </div>
  <div class="bottom-bar-container">
    <BottomBarVue />
  </div>
    
  
</div>
</template>


<script> 
/* eslint-disable */
import { initializeApp } from "firebase/app"
import firebaseConfig from "./config/firebaseConfig"
import NavBarVue from './components/NavBar.vue'
import FooterVue from './components/Footer.vue'
import HeaderVue from './components/Header.vue'
import BottomBarVue from './components/BottomBar.vue'

export default {
  name: 'App',
  components: {
    HeaderVue,
    NavBarVue,
    FooterVue,
    BottomBarVue
  },
  
  data() {
    return {
      
    }
  },

  mounted() {
    const firebaseApp = initializeApp(firebaseConfig)
  }
}

</script>


<style>
#app {
  font-family: 'Noto Sans KR', sans-serif;
  text-align: center;
  height: 100vh;
  color: #000000;
}
.main-container {
  padding-top: 30px;
  position: relative;
  width: 700px;
  min-height: 100vh;
  margin: 0 auto;
  border: solid 1px #dadada;
  padding-bottom: 70px; 
  box-sizing: border-box; 
}
.bottom-bar-container {
  position: fixed;
  width: 700px;
  height: 60px;
  bottom: 0;
  background-color: white; 
  border: solid 1px gray;
  z-index: 998;
  left: 50%;
  transform: translateX(-50%);
}
</style>
