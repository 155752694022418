<template>
<div id="knowhow-item-container">
  <div class="knowhow-contents">
    <div class="knowhow-title">
      <p class="black-light">서베이지의 EASY한 서베이 꿀팁 #8</p>
      <p class="green">구글폼 질문 유형 9가지 총정리!</p>
    </div>
    <div class="knowhow-content">
      <p><img class="img1" src="@/assets/knowhow/one/surveytips8thumbnail.png" width="300"></p>
      <p class="black-bold-">적절한 질문 형식을 이용하면 질문에 대해 더 효과적으로 응답을 이끌어 낼 수 있습니다.
        <br>구글폼에 있는 다양한 질문 유형을 언제, 어떻게 써야 하는지 서베이지가 설명해드릴게요!</p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">1. 단답형</p>
      <p class="black-light">단답형은 한 단어나 한 문장 정도의 짧은 응답을 받을 때 사용합니다. 주로 이름, 연락처, 이메일 등을 수집해야 할 때 활용됩니다. TIP) 일정 수준의 글자 수를 초과되면 응답자의 화면에는 초과된 분량의 글이 더 이상 보이지 않게 되므로 응답자가 글을 작성하는데 불편을 겪을 수 있어요.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips8image(1).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">2. 장문형</p>
      <p class="black-light">장문형은 단답형보다 더 긴 답변을 얻고 싶을 때, 주로 응답자의 의견이 필요한 경우에 활용됩니다. 장문형에서는 긴 글을 작성하여도 글이 잘리지 않아 설문 제작자뿐만 아니라 응답자도 답변을 한 눈에 확인할 수 있다는 장점이 있습니다.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips8image(2).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">3. 객관식 질문</p>
      <p class="black-light">객관식 질문에는 응답자는 보기 중 하나의 응답만을 선택할 수 있습니다. 옵션 추가 옆에 파란색 글씨로 써 있는 '기타 추가'는 응답자가 기타 의견을 직접 텍스트로 추가할 수 있는 기능입니다. TIP) 복수 응답을 가능하게 하고 싶다면 객관식 질문이 아닌 '체크박스'를 사용하시면 돼요!</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips8image(3).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">4. 체크박스</p>
      <p class="black-light">객관식 질문과 달리 체크박스를 사용하면 응답자는 1개 이상의 선택지를 클릭할 수 있게 됩니다. 체크박스는 선택지를 여러 개 고를 수 있어야 하는 복수 응답이 필요한 질문에 사용하면 좋습니다.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips8image(4).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">5. 드롭다운</p>
      <p class="black-light">드롭다운은 옵션이 너무 많아 문항이 복잡해 보일 때 옵션들을 깔끔하게 모아서 보여줄 수 있습니다. 옵션이 10개 이상인 질문을 객관식으로 만들면 설문지도 굉장히 길어지고, 응답자 입장에서 문항이 장황해 보이기 때문에 드롭다운으로 싹 정리하면 됩니다.</p>
        <p><img class="img" src="@/assets/knowhow/one/surveytips8image(5).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">6. 파일 업로드</p>
      <p class="black-light">응답자에게 사진이나 문서 등을 받으려면 파일 업로드를 사용하면 됩니다. 원하는 파일 형식이 있는 경우, '특정 파일 형식만 허용' 버튼을 클릭하면 문서, 프레젠테이션, 스프레드시트 등 원하는 파일 형식을 하나 이상 선택할 수 있습니다. 최대 파일 수(10개)와 최대 파일 크기(1GB)도 설정할 수 있습니다.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips8image(6).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">7. 선형배율</p>
      <p class="black-light">선형배율은 만족도 조사를 할 때 매우 효과적입니다. 시작 숫자는 0 또는 1, 마지막 숫자는 2~10까지 설정할 수 있습니다. 그리고 시작과 끝 라벨에 각각 ‘매우 불만족스러웠다’와 ‘매우 만족스러웠다’ 등 숫자가 의미하는 단계를 적어주면 됩니다.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips8image(7).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">8. 객관식 그리드</p>
      <p class="black-light">격자 모양을 뜻하는 그리드의 뜻처럼 객관식 그리드로는 '표'를 만들 수 있습니다. 아래 사진에 초록색 화살표로 표시되어 있는 '각 행에 응답 필요' 버튼을 눌러 활성화하면 응답자가 응답을 하지 않고 넘어가는 실수를 방지할 수 있습니다. 앞서 ‘객관식 질문’에서 설명해드린 것과 같이 객관식 그리드에서도 응답자는 하나의 옵션만을 선택할 수 있습니다. TIP) 행 또는 열에서 옵션 순서를 바꾸고 싶다면 마우스를 항목 위에 올려주면 좌측에 6개의 회색 점이 나타나고, 그 점을 잡아서 위아래로 움직여주면 옵션 순서를 바꿀 수 있습니다.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips8image(8).png" width="400"></p>
    </div>
    <div class="knowhow-content">
      <p class="black-bold">9. 체크박스 그리드</p>
      <p class="black-light">응답자가 여러 선택지를 고르는 것을 가능하게 하기 위해서는 체크박스 그리드를 이용하면 됩니다. 체크박스 그리드는 응답자의 복수 응답을 허용하고 싶을 때 사용합니다.</p>
      <p><img class="img" src="@/assets/knowhow/one/surveytips8image(9).png" width="400"></p>
    <p><img src="@/assets/knowhow/08.png" class="img2" width="30"></p>
        <p class="black-bold-">구글폼을 만들 때 사용해 볼 수 있는 9가지 질문 유형을 살펴봤습니다.<br> 
        이제 구글폼 기능 100%로 활용해보세요!</p>
    </div>
  </div>
</div>
</template>
 
<script>
export default {
  methods: {
   
  }
}
</script>

<style>
#knowhow-item-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Noto Sans KR', sans-serif;
    margin-top: 60px;
    text-align: center;
}

#knowhow-item-container .knowhow-title p {
    font-size: 1.2rem;
    font-weight: 500;
    color: #0AAB00;
    margin-top: 6px;
}

#knowhow-item-container .knowhow-title .black-light {
    font-size: 1rem;
    font-weight: 300;
    color: black;
    padding-left: 10%;
    text-align: left;
    margin-bottom: 0;
    line-height: 30px;
}

#knowhow-item-container .img1 {
    margin-top: 50px;
}

#knowhow-item-container .img {
    margin-top: 30px;
    padding-left: 0 !important;
}

.knowhow-content {
    margin-bottom: 120px;
    padding-right: 10%;
    padding-left: 10%;
}

.knowhow-content .black-light {
    font-weight: 300;
    text-align: left;
    font-size: 1rem;
    line-height: 30px;

}

.knowhow-content .black-bold {
    font-weight: 700;
    font-size: 1.1rem;
    text-align: left;
}

.knowhow-content .img2 {
    margin-top: 100px;
    padding-left: 0 !important;
    margin-bottom: 20px;
}

.black-bold- {
    font-size: 1rem;
    color: gray;
    font-weight: 400;
    line-height: 30px;

}

.green {
    padding-left: 10%;
    text-align: left;
}</style>