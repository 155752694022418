<template>
    <div class="home-brandstory-container">
        home brandstory
    </div>
</template>

<script>
export default {

}
</script>

<style>
.home-brandstory-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    background-color: rgb(200, 253, 160);
}
</style>